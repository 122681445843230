import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import ReactSlidy from 'react-slidy'
import 'react-slidy/lib/styles.css'
import MarkdownContent from "./MardownContent";
import { groupBy } from "lodash";

const ImageCarouselWithText = ({ gridItems }) => {
  const imagesGroupedByCategoryId = groupBy(gridItems, 'categoryid');
  return (<div className={ 'columns is-multiline features is-vcentered' }>
    {
      Object.entries(imagesGroupedByCategoryId).map(function ([ key, items ]) {
        return <div key={key
        } className="column is-6 is-align-self-flex-start">
          <ReactSlidy keyboardNavigation>
            { items.map(function (item, index) {
              return <PreviewCompatibleImage key={key + index + item.categoryid} className={'fixed-size-image'} imageInfo={ item }/>
            }) }
          </ReactSlidy>
          { items.map(function (item, index) {
            return index === 0 ? <MarkdownContent key={index} className={ 'column is-12 fixed-size-block' } content={ item.text }/> : null
          }) }
        </div>
      })

    }
  </div>)

}

ImageCarouselWithText.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
      text: PropTypes.string,
      categoryid: PropTypes.number,
    })
  ),
}

export default ImageCarouselWithText
